/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"fotoegyptsafari"}>
        <Column className="--menu pb--08 pt--08" style={{"backgroundColor":"rgba(255,255,255,1)"}} menu={true}>
          
          <Menu className="--right" style={{"maxWidth":1310}} fullscreen={false}>
            
            <ColumnWrapper className="menu-logo-box --center" style={{"maxWidth":310}}>
              
              <Image style={{"maxWidth":142}} alt={""} src={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png"} svg={false} href={"/"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=350x_.png 350w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=660x_.png 660w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png 860w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=2000x_.png 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--style4 --right">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kurzy"} target={""} content={"Kurzy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/zajezdy"} target={""} content={"Zájezdy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/obchod"} target={""} content={"Obchod"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/sluzby"} target={""} content={"Služby"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/bazen"} target={""} content={"Bazén"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/klub"} target={""} content={"Klub"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/o-nas"} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/fotogalerie"} target={""} content={"Fotogalerie"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kontakt"} target={""} content={"Kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column name={"oq9bia72f9e"} style={{"paddingTop":27,"paddingBottom":23.6484375}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box ff--3" style={{"marginTop":0,"paddingTop":0}} content={"Egypt - safari"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"qkjg8wb8r7"} style={{"paddingTop":14,"paddingBottom":0}} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center" style={{"marginBottom":0,"paddingBottom":0}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/4c9d4f98da5f43b4aedd1ba5ea0042de_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/4c9d4f98da5f43b4aedd1ba5ea0042de_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/4c9d4f98da5f43b4aedd1ba5ea0042de_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/4c9d4f98da5f43b4aedd1ba5ea0042de_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/4c9d4f98da5f43b4aedd1ba5ea0042de_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/4c9d4f98da5f43b4aedd1ba5ea0042de_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/d8a950ad693f4294b2617e91eefae159_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/d8a950ad693f4294b2617e91eefae159_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/d8a950ad693f4294b2617e91eefae159_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/d8a950ad693f4294b2617e91eefae159_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/d8a950ad693f4294b2617e91eefae159_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/d8a950ad693f4294b2617e91eefae159_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/fd8de6cf05194cc2b8671a5d411168e5_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/fd8de6cf05194cc2b8671a5d411168e5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/fd8de6cf05194cc2b8671a5d411168e5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/fd8de6cf05194cc2b8671a5d411168e5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/fd8de6cf05194cc2b8671a5d411168e5_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/1abefb543ffe4277a3a6901388a42068_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/1abefb543ffe4277a3a6901388a42068_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/1abefb543ffe4277a3a6901388a42068_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/1abefb543ffe4277a3a6901388a42068_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/1abefb543ffe4277a3a6901388a42068_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/1abefb543ffe4277a3a6901388a42068_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":17.25,"paddingTop":0}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/7de481143b3448b691bb7a96e5a72d74_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/7de481143b3448b691bb7a96e5a72d74_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/7de481143b3448b691bb7a96e5a72d74_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/7de481143b3448b691bb7a96e5a72d74_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/7de481143b3448b691bb7a96e5a72d74_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/7de481143b3448b691bb7a96e5a72d74_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/06fe7a73852a48d5952227c5e8a13b72_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/06fe7a73852a48d5952227c5e8a13b72_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/06fe7a73852a48d5952227c5e8a13b72_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/06fe7a73852a48d5952227c5e8a13b72_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/06fe7a73852a48d5952227c5e8a13b72_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/06fe7a73852a48d5952227c5e8a13b72_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/25b98f94ff174ea1939b554b0eff7ecc_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/25b98f94ff174ea1939b554b0eff7ecc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/25b98f94ff174ea1939b554b0eff7ecc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/25b98f94ff174ea1939b554b0eff7ecc_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/25b98f94ff174ea1939b554b0eff7ecc_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/25b98f94ff174ea1939b554b0eff7ecc_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/1dbeb58522e24648b3612e30d0e882bc_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/1dbeb58522e24648b3612e30d0e882bc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/1dbeb58522e24648b3612e30d0e882bc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/1dbeb58522e24648b3612e30d0e882bc_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/1dbeb58522e24648b3612e30d0e882bc_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/1dbeb58522e24648b3612e30d0e882bc_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":19.5703125}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/22a6d099d4094aecb24aceadf306d50d_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/22a6d099d4094aecb24aceadf306d50d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/22a6d099d4094aecb24aceadf306d50d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/22a6d099d4094aecb24aceadf306d50d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/22a6d099d4094aecb24aceadf306d50d_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/d1f15652d36642728b6f33cb9ca4a07b_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/d1f15652d36642728b6f33cb9ca4a07b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/d1f15652d36642728b6f33cb9ca4a07b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/d1f15652d36642728b6f33cb9ca4a07b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/d1f15652d36642728b6f33cb9ca4a07b_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/d1f15652d36642728b6f33cb9ca4a07b_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/7a54ec1088424d34896c180fdc9e591b_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/7a54ec1088424d34896c180fdc9e591b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/7a54ec1088424d34896c180fdc9e591b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/7a54ec1088424d34896c180fdc9e591b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/7a54ec1088424d34896c180fdc9e591b_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/7a54ec1088424d34896c180fdc9e591b_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/3f0e72d428284a8f88f12a5d4b523755_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/3f0e72d428284a8f88f12a5d4b523755_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/3f0e72d428284a8f88f12a5d4b523755_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/3f0e72d428284a8f88f12a5d4b523755_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/3f0e72d428284a8f88f12a5d4b523755_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"xof0uc9eyb"} style={{"paddingTop":15,"paddingBottom":15.9765625}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Text className="text-box ff--3" content={"<span style=\"color: rgb(2, 2, 2);\">Fotografie poskytl Martin Trnka - děkujeme.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1b4itn5" style={{"marginTop":17,"paddingTop":30,"paddingBottom":7.6171875}} layout={"l1"} css={css`
      
    background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 title-box--invert mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Orca diving, s.r.o.</span>"}>
              </Title>

              <Text className="text-box ff--3 text-box--invert" style={{"marginBottom":0,"paddingBottom":0}} content={"<span style=\"color: rgb(0, 0, 0);\">Ledařská 433/9, Praha 4, 147 00, Česká Republika</span>"}>
              </Text>

              <Image style={{"maxWidth":236,"marginTop":16.6875,"paddingTop":0}} alt={""} src={"https://cdn.swbpg.com/o/13078/000a415e28ce44c5972c4f22d2c183db.PNG"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={""} content={null}>
              </Image>

              <Image style={{"maxWidth":36,"marginTop":0,"paddingTop":10,"paddingBottom":0}} alt={""} src={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png"} svg={false} url={"https://www.facebook.com/orcadivingpotapeni"} href={"https://www.facebook.com/orcadivingpotapeni"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png 350w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}